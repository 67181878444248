





































































































































@import '../../utilities/config';
.o-payproject {
  padding: 40px;
  border: 1px solid gray('gray-150');
  background-color: gray('white');
  &__amount {
    font-size: 30px;
    font-weight: 500;
    color: gray('gray-400');
    line-height: 26px;
    margin-bottom: 24px;
    &__label {
      display: block;
      font-size: 17px;
      color: gray('gray-700');
    }
  }
  &__grid {
    margin-bottom: 30px;
    border: 1px solid gray('gray-150');
    border-bottom: 0;
    &__amount {
      font-size: 16px;
    }
  }
  &__row {
    @media screen and (min-width: breakpoint-min('md')) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
    }
    border-bottom: 1px solid gray('gray-150');
    cursor: pointer;
    position: relative;
    padding: 0.75rem 60px 0.75rem 0;
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;

    &--download {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media screen and (min-width: breakpoint-min('md')) {
        position: relative;
        transform: translateY(0%);
      }
    }
  }
  &__logobox {
    border: 1px solid gray('gray-150');
    background-color: gray('white');
    padding: 15px;
    text-align: center;
    @media screen and (min-width: breakpoint-min('md')) {
      min-height: 120px;
    }
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__img {
      max-width: 100%;
      height: auto;
    }
  }
  &__subtitle {
    font-size: 17px;
    color: gray('gray-700');
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  &__address {
    margin-top: 8px;
    margin-left: 15px;
    font-style: italic;
    display: inline-block;
  }
}
